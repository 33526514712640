<template>
  <div class="HOME">
    <h2>Artist Resources</h2>
    <div><button @click="MusicDistro" id="resource-buttons">Save 7% On Music Distribution</button></div>
    <div><button @click="SongMastering" id="resource-buttons">Free Song Mastering</button></div>
    <div><button @click="FreeWebsite" id="resource-buttons">Free Artist Website</button></div>
    <div><button @click="FreeBeats" id="resource-buttons">Free Commercial Beats</button></div>
    <div><button @click="FollowForUpdates" id="resource-buttons">Follow For Updates</button></div>
  </div>
</template>

<script>


// import { ref } from 'vue';
export default {
  methods: {
    FollowForUpdates: function() {
         window.open("https://jaewilliams.com");
    },
     MusicDistro: function() {
         window.open("https://distrokid.com/vip/seven/1126194");
    },
     SongMastering: function() {
         window.open("https://www.bandlab.com/mastering");
    },
     FreeWebsite: function() {
         window.open("https://beacons.ai/signup?c=imjaewilliams");
    },
     FreeBeats: function() {
         window.open("https://www.beatpackage.com/free-beat-list44048473");
    }

  },
   created(){
        document.title = "Home | Musician Toolkit";    
    }
};
</script>

<style>
#hlogo {
    height: 128px;
    width: 160px;
    align-items: center;   
}
a {
  color: rgba(240, 175, 10, 0.838);
  font-size: large;
}
a:hover{
  color: #fcc309;
}
#resource-buttons {
    margin-top: 25px;
    border-radius: 10px;
    background: transparent;
    border: 2px solid rgba(240, 175, 10, 0.838);
    color: white;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: large;
    font-weight: 600;
    width: 225px;
    height: 60px;
    align-items: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
}

#resource-buttons:hover {
  background: #b68f0f;
}

</style>


