import { createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyBKetd8a57H4tLJ4-CVwHjKkyKkr3By5pA",
    authDomain: "musiciantoolkit.app",
    projectId: "musician-toolkit",
    storageBucket: "musician-toolkit.appspot.com",
    messagingSenderId: "147880669179",
    appId: "1:147880669179:web:141fbb2a87ad885e82655e",
    measurementId: "G-EDR7ZSC487"
  };

  firebase.initializeApp(firebaseConfig);


createApp(App).use(store).use(router).mount('#app')
