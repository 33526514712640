<template>
<div>
  <Sidebar v-if="!['Login'].includes($route.name)"/>
  <div :style="[!['Login'].includes($route.name)? { 'margin-left': sidebarWidth} : null]">
    <router-view />
  </div>
</div>
</template>

<script>
// :style="{ 'margin-left': sidebarWidth}
import {useRouter, useRoute} from 'vue-router';
import firebase from 'firebase';
import {onBeforeMount} from 'vue';
import Sidebar from '@/components/sidebar/Sidebar';
import { sidebarWidth } from '@/components/sidebar/state';

export default {
  components: { Sidebar },
  setup() {
    const router = useRouter();
    const route = useRoute();
    onBeforeMount(() =>{
      firebase.auth().onAuthStateChanged((user) => {
        if(!user){
          router.replace('/login');
        } else if (route.path == "/login"){
          router.replace('/');     
        }
     
      }); // checks to see if a user is signed in or out
      
      
    });

     return { sidebarWidth };
  }
}
</script>

<style lang="scss">

html {
    overflow: hidden;
    height: 100%;
}
body {
  overflow: auto;
  height: 100%;
  background:  url('./assets/bg.jpg') repeat center/cover fixed !important;
  color: rgba(240, 175, 10, 0.838);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}


</style>
