<script>
import SidebarLink from './SidebarLink'
import firebase from 'firebase';
// import {onBeforeMount} from 'vue';
import { collapsed, toggleSidebar, sidebarWidth } from '../sidebar/state.js'
export default {
  props: {},
  components: { SidebarLink },
  setup() {
       const user = firebase.auth().currentUser.photoURL;
       return {collapsed, toggleSidebar, sidebarWidth, user}
  },
  methods: {
    Logout: function() {
      firebase.auth().signOut();
  }
  }
}
</script>

<template>
  <div class="sidebar" :style="{ width: sidebarWidth }">
    <h1>
      <span v-if="collapsed">
        <br>
        <div><img :src="user" height="40" width="40"></div>
      </span>
      <span v-else><div><img :src="user" height="40" width="40"></div></span>  
    </h1>

    <SidebarLink to="/" icon="fas fa-home">Home</SidebarLink>
    <SidebarLink to="/community" icon="fas fa-user-friends">Community</SidebarLink>
    <SidebarLink to="/feedback" icon="fas fa-bullhorn">Give Feedback</SidebarLink>
    <br>
    <i class="fas fa-sign-out-alt" @click="Logout"></i>
    <span
      class="collapse-icon"
      :class="{ 'rotate-180': collapsed }"
      @click="toggleSidebar"
    >
      <i class="fas fa-angle-double-left" />
    </span>
  </div>
</template>

<style>
:root {
  --sidebar-bg-color: rgba(240, 175, 10, 0.838);;
  --sidebar-item-hover: #fcc309;
  --sidebar-item-active: #ffc506;
}
</style>

<style scoped>
i {
  font-size: x-large;
}
img{
  border-radius: 50%;
  margin-right: 5px;
}
.sidebar {
  color: white;
  background-color: var(--sidebar-bg-color);
  float: left;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0.5em;
  transition: 0.3s ease;
  display: flex;
  flex-direction: column;
}
.sidebar h1 {
  height: 2.5em;
}
.collapse-icon {
  position: absolute;
  bottom: 0;
  padding: 0.75em;
  color: rgba(255, 255, 255, 0.7);
  transition: 0.2s linear;
}
.rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s linear;
}
</style>